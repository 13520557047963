import { SVGProps } from 'react';

export const VisibilityPublic = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="13"
    viewBox="0 0 18 13"
    width="18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.8 0.560547C4.8 0.560547 1.384 3.04855 0 6.56055C1.384 10.0725 4.8 12.5605 8.8 12.5605C12.8 12.5605 16.216 10.0725 17.6 6.56055C16.216 3.04855 12.8 0.560547 8.8 0.560547ZM8.8 10.5605C6.592 10.5605 4.8 8.76855 4.8 6.56055C4.8 4.35255 6.592 2.56055 8.8 2.56055C11.008 2.56055 12.8 4.35255 12.8 6.56055C12.8 8.76855 11.008 10.5605 8.8 10.5605ZM8.8 4.16055C7.472 4.16055 6.4 5.23255 6.4 6.56055C6.4 7.88855 7.472 8.96055 8.8 8.96055C10.128 8.96055 11.2 7.88855 11.2 6.56055C11.2 5.23255 10.128 4.16055 8.8 4.16055Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);
