import { SVGProps } from 'react';

export const VisibilityPrivate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    height="17"
    viewBox="0 0 20 17"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.76316 3.92897C12.0874 3.92897 13.9737 5.81528 13.9737 8.13949C13.9737 8.68686 13.8642 9.20055 13.6705 9.68055L16.1295 12.1395C17.4011 11.0784 18.4032 9.70581 19.0179 8.13949C17.5611 4.44265 13.9653 1.8237 9.75474 1.8237C8.57579 1.8237 7.44737 2.03423 6.40316 2.41318L8.22211 4.23213C8.70211 4.03844 9.21579 3.92897 9.76316 3.92897ZM1.34211 1.63002L3.26211 3.55002L3.64947 3.93739C2.25158 5.02371 1.15684 6.47213 0.5 8.13949C1.95684 11.8363 5.55263 14.4553 9.76316 14.4553C11.0684 14.4553 12.3147 14.2027 13.4516 13.7479L13.8053 14.1016L16.2726 16.5605L17.3421 15.4911L2.41158 0.560547L1.34211 1.63002ZM5.99895 6.28686L7.30421 7.59213C7.26211 7.76897 7.23684 7.95423 7.23684 8.13949C7.23684 9.53739 8.36526 10.6658 9.76316 10.6658C9.94842 10.6658 10.1337 10.6405 10.3105 10.5984L11.6158 11.9037C11.0516 12.1816 10.4284 12.35 9.76316 12.35C7.43895 12.35 5.55263 10.4637 5.55263 8.13949C5.55263 7.47423 5.72105 6.85107 5.99895 6.28686ZM9.62842 5.63002L12.2811 8.28265L12.2979 8.14792C12.2979 6.75002 11.1695 5.6216 9.77158 5.6216L9.62842 5.63002Z"
      fill="black"
      fillOpacity="0.87"
    />
  </svg>
);
